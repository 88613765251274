<template>
  <div class="body">
    <div class="card">
      <div
        class="card-item"
        v-for="(item, index) in names"
        v-bind:key="index"
        @click="jump(item.url)"
      >
        <el-card
          style="margin-top: 15px; background-color: rgba(255, 255, 255, 0.8)"
        >
          <img :src="item.icon" class="image" />
          <div style="padding: 14px" :class="{ 'red-text': item.isQ }">
            {{ item.name }}
          </div>
        </el-card>
      </div>
      <div class="beian">闽ICP备2024034281号</div>
    </div>
    <el-drawer
      title="每日提醒"
      :visible.sync="drawer"
      :direction="direction"
      :modal="false"
    >
      <el-button type="primary" @click="sign()">萌云签到！！！！</el-button>
    </el-drawer>
  </div>
</template>
<script>
import { isNewDay } from "../api/tool";
export default {
  data() {
    return {
      input: "",
      drawer: false,
      direction: "btt",
      names: [
        "bilibili",
        "微博",
        "知乎",
        "萌云",
        "vivo",
        "白鹭学院",
        "2dfan",
        "nyaa",
        "tinypng",
        "琉璃神社",
        "hanime1",
        "微信后台",
      ],
      currentDate: new Date(),
    };
  },
  created() {
    console.log("签到没了，暂时不需要弹东西了");
    let time1 = localStorage.getItem("listLoginTime");
    if (time1) {
      if (isNewDay(time1)) {
        this.drawer = false;
        console.log("又是新的一天呢");
      } else {
        console.log("今天登录过了呢");
      }
    } else {
      console.log("第一次登录");
      this.drawer = false;
    }
    this.setListLoginTime();

    this.names = [
      {
        name: "bilibili",
        url: "https://www.bilibili.com/",
        icon: require("../../static/icon/bilibili.png"),
        isQ: false,
      },
      {
        name: "微博",
        url: "https://weibo.com/u/2204454944",
        icon: require("../../static/icon/weibo.png"),
        isQ: false,
      },
      {
        name: "知乎",
        url: "https://www.zhihu.com/",
        icon: require("../../static/icon/zhihu.png"),
        isQ: false,
      },
      {
        name: "萌云",
        url: "https://www.cutecloud.net/",
        icon: require("../../static/icon/mengyun.png"),
        isQ: false,
      },
      {
        name: "白鹭学院",
        url: "https://srsg.moe/#term-684",
        icon: require("../../static/icon/mengmeng.png"),
        isQ: false,
      },
      {
        name: "chatGpt",
        url: "https://chatgpt.com/",
        icon: require("../../static/icon/chatGpt.png"),
        isQ: true,
      },
      {
        name: "LKs 网站推荐合集",
        url: "https://lkssite.vip/?ref=www.zhaicangku.com",
        icon: require("../../static/icon/mengmeng.png"),
        isQ: false,
      },
      {
        name: "宅仓库",
        url: "https://www.zhaicangku.com/",
        icon: require("../../static/icon/mengmeng.png"),
        isQ: false,
      },
      {
        name: "琉璃神社",
        url: "https://hacg.meme/",
        icon: require("../../static/icon/liuli.png"),
        isQ: true,
      },
      {
        name: "2dfan",
        url: "https://2dfan.com/",
        icon: require("../../static/icon/mengmeng.png"),
        isQ: true,
      },
      {
        name: "hanime1",
        url: "https://hanime1.me",
        icon: require("../../static/icon/mengmeng.png"),
        isQ: true,
      },
      {
        name: "nyaa",
        url: "https://sukebei.nyaa.si/",
        icon: require("../../static/icon/mengmeng.png"),
        isQ: true,
      },
    ];
  },
  methods: {
    jump(url) {
      console.log(url);
      window.open(url, "_blank");
    },
    setListLoginTime() {
      let time = new Date();
      localStorage.setItem("listLoginTime", time);
    },
    sign() {
      (this.drawer = false),
        window.open("https://main.cute-cloud.de/user", "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  margin-top: 5rem;
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 40px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.card {
  margin-left: 10rem;
  margin-right: 10rem;
  display: flex;
  flex-wrap: wrap;
  .card-item {
    width: 19rem;
    margin-left: 2rem;
    margin-right: 2rem;
    cursor: url(../../static/mouse/BLUE1/click.png), pointer;
    background-color: rgba(255, 255, 255, 0);
  }
}

.red-text {
  color: red;
}
.beian {
  margin-top: 1.5rem;
  font-weight: 600;
  font-size: 14px;
  color: gray;
  text-align: center;
  width: 100%;
}
</style>
